<template>
    <OSizerPanel ref="sizerPanelRef" :id="leftSidepanel ? dataGridControl.menuTabs.sizerContainerId + '_leftPanel' : dataGridControl.menuTabs.sizerContainerId" class="grid-menu-sizer" :width="initialWidth" expand collapse hideIcons :maxWidth="maxWidth"
        :minWidth="minWidth" @collapsed="side => dataGridControl.menuTabs.handleSizerCollapse(side)" :initiallyCollapsed="!initialVisible" useEvents>
        <OColContainer class="grid-menu bg-body" ref="containerRef">

            <GridSidePanelTabs v-if="!leftSidepanel && dataGridControl.menuTabs.sizerState === 'collapsedLeft'" skipCollapse/>

            <ErrorRenderer v-if="capturedError"/>

            <div v-else class="row-container flex-grow-1 min-sizes overflow-hidden left-sidepanel" v-if="dataGridControl.menuTabs.visible">
                    <div v-if="dataGridControl.menuTabs.detailsTabEnabled"
                        class="row-container position-relative container-scope"
                        :class="{ 'd-none': dataGridControl.menuTabs.activeTab !== 'details' }" role="tabpanel"
                        aria-labelledby="v-detailsiframe-tab">
                        <div class="min-h-unset o365-card-header hstack pt-2 ps-2">
                            <GridSidePanelTabs :left="leftSidepanel" horizontal :detailTabTitle="dataGridControl.menuTabs.detailsIframeTitle">
                                <template v-if="$slots.detailActions" #detailActions>
                                    <slot name="detailActions"></slot>
                                </template>
                            </GridSidePanelTabs>
                        </div>
                        <div class="h-100 h-100 position-relative row-container">
                            <DetailsTab :menuTabs="dataGridControl.menuTabs" :tabs="tabs" :iframeSrc="iframeSrc" :detailTabTitle="dataGridControl.menuTabs.detailsIframeTitle">
                                <template v-if="$slots.detailTab" #detailTab>
                                    <slot name="detailTab"></slot>
                                </template>
                                <template v-for="tab in tabs" v-slot:[`tab(${tab.id})`]>
                                    <slot :name="`tab(${tab.id})`"></slot>
                                </template>
                            </DetailsTab>
                        </div>
                        <div class="min-h-unset o365-footer py-1 p-1 d-flex">
                            <div role="button" @click="$event => copyDetailUrl($event)" 
                                class="hstack o365-grid-detail-link-container px-2 text-primary">
                                <span class="text-truncate" v-tooltip="{ title: detailIframeUrlCopyTooltip, tooltipRef: tooltip => detailIframeUrlCopyTooltipObj=tooltip }">
                                    {{detailIframeDisplayUrl}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row-container"
                        :class="{ 'd-none': dataGridControl.menuTabs.activeTab === 'details' }"
                        key="options" role="tabpanel" aria-labelledby="v-options">
                        <div class="min-h-unset o365-card-header hstack pt-2 ps-2">
                            <GridSidePanelTabs :left="leftSidepanel" horizontal />
                        </div>
                        <div class="container-scope row-container h-100 w-100 px-1 ps-2">
                            <OTabs :id="`${dataGridControl.menuTabs.sizerContainerId}_options_tabs`" ref="gridOptionsTabs" 
                                tabsClass="nav nav-underline" hideNavbar>
                                <OTab :title="$t('Filters')" :active="dataGridControl.menuTabs.activeTab === 'filters'" :id="`${dataGridControl.menuTabs.sizerContainerId}_filters`" 
                                    linkClass="text-primary">
                                    <template #title>
                                        <i class="bi bi-funnel"></i>
                                        {{$t('Filters')}}
                                    </template>
                                    <ActiveFilters  hideHeader
                                        :dataObject="dataGridControl.dataObject" :gridRef="dataGridControl">
                                        <template #filterBottom>
                                            <FilterStringEditor v-if="dataGridControl.dataObject" :filterObject="dataGridControl.dataObject.filterObject"/>
                                            <slot name="filterBottom"></slot>
                                        </template>
                                    </ActiveFilters>
                                </OTab>
                                <OTab :title="$t('Columns')" :active="dataGridControl.menuTabs.activeTab === 'columns'" :id="`${dataGridControl.menuTabs.sizerContainerId}_columns`" 
                                    linkClass="text-primary border-top-0">
                                    <template #title>
                                        <i class="bi bi-layout-three-columns"></i>
                                        {{$t('Columns')}}
                                    </template>
                                   <ORowContainer class="mt-1">
                                        <template v-if="columnPanelTabsLength > 0">
                                            <OTabs>
                                                <OTab :title="$t('Columns')" active v-if="shouldShowMenuItem('columns')">
                                                    <template #title>
                                                        <i class="bi bi-grid-1x2"></i>
                                                        {{$t('Columns')}}
                                                    </template>
                                                    <OColumnChooser :gridRef="dataGridControl" :watchTarget="columnsWatchTarget"/>
                                                </OTab>
                                                <OTab :title="$t('Layouts')" v-if="dataGridControl.menuTabs.layoutsTabEnabled && shouldShowMenuItem('layouts')">
                                                    <template #title>
                                                        <i class="bi bi-grid-1x2"></i>
                                                        {{$t('Layouts')}}
                                                    </template>
                                                    <GridLayouts hideHeader/>
                                                </OTab>
                                                <OTab :title="$t('Properties')" v-if="dataGridControl.dataObject?.hasPropertiesData">
                                                    <template #title>
                                                        <i class="bi bi-layout-text-window"></i>
                                                        {{$t('Properties')}}
                                                    </template>
                                                    <div class="hstack min-h-unset mt-2">
                                                        <small class="text-truncate">{{$t('Select property to add column in grid')}}</small>
                                                    </div>
                                                    <PropertiesList :dataObject="dataGridControl.dataObject" hideHeader/>
                                                </OTab>
                                                <OTab :title="$t('Group By')" v-if="dataGridControl.hasNodeData && dataGridControl.nodeData.groupBy">
                                                    <template #title>
                                                        <i class="bi bi-diagram-3"></i>
                                                        {{$t('Group By')}}
                                                    </template>
                                                    <GroupBy hideHeader/>
                                                </OTab>
                                                <OTab :title="$t('Summary')" v-if="dataGridControl.props.userSummaryAggregates">
                                                    <template #title>
                                                        <i class="bi bi-bar-chart"></i>
                                                        {{$t('Summary')}}
                                                    </template>
                                                    <SummaryData hideHeader/>
                                                </OTab>
                                            </OTabs>
                                        </template>
                                        <template v-else>
                                            <OColumnChooser :grid-ref="dataGridControl" :watchTarget="columnsWatchTarget"/>
                                        </template>
                                    </ORowContainer>
                                </OTab>
                                <OTab :title="$t('Export')" :active="dataGridControl.menuTabs.activeTab === 'export'" :id="`${dataGridControl.menuTabs.sizerContainerId}_export`" 
                                    linkClass="text-primary border-top-0">
                                    <template #title>
                                        <i class="bi bi-database"></i>
                                        {{
                                            dataGridControl.importData
                                                ? $t('Export / Import')
                                                : $t('Export')      
                                        }}
                                    </template>
                                    <ORowContainer class="mt-1">
                                        <ExportData :gridRef="dataGridControl"  hideHeader
                                            :watchTarget="columnsWatchTarget" />
                                    </ORowContainer>
                                </OTab>
                                <!-- <OTab :title="$t('Layouts')" v-if="dataGridControl.menuTabs.layoutsTabEnabled" :active="dataGridControl.menuTabs.activeTab === 'layouts'" :id="`${dataGridControl.menuTabs.sizerContainerId}_layouts`" -->
                                    <!-- linkClass="text-primary border-top-0"> -->
                                    <!-- <template #title> -->
                                        <!-- <i class="bi bi-grid-1x2"></i> -->
                                        <!-- {{$t('Layouts')}} -->
                                    <!-- </template> -->
                                    <!-- <ORowContainer> -->
                                        <!-- <GridLayouts  hideHeader/> -->
                                    <!-- </ORowContainer> -->
                                <!-- </OTab> -->
                                <OTab :title="$t('Group By Folder')" v-if="dataGridControl.menuTabs.groupByFoldersTabEnabled" :id="`${dataGridControl.menuTabs.sizerContainerId}_groupbyfolder`"
                                    linkClass="text-primary border-top-0">
                                    <template #title>
                                        <i class="bi bi-folder"></i>
                                        {{$t('Group By Folder')}}
                                    </template>
                                    <ORowContainer>
                                        <GroupByFolder :dataObject="dataGridControl.dataObject"  hideHeader/>
                                    </ORowContainer>
                                </OTab>
                            </OTabs>
                    </div>
                    <div class="min-h-unset o365-footer py-1 p-1 d-flex"></div>
                </div>
            </div>

            <GridSidePanelTabs v-if="leftSidepanel && dataGridControl.menuTabs.sizerState === 'collapsedRight'" skipCollapse left/>

        </OColContainer>
    </OSizerPanel>
</template>

<script lang="ts">
export interface GridMenuProps {
    initialVisible?: boolean;
    tabContainerClass?: string;
    initialWidth?: string;
    iframeSrc?: string;
    detailTabTitle?: string;
    groupByFolders?: boolean;
    tabs?: IDataGridMenuTab[];
    maxWidth?: string;
    minWidth?: string;
    messageChannelId?: string;
    messageChannelFunctions?: Record<string, Function>;
    leftSidepanel?: boolean;
    containerHasHeader?: boolean;
};

</script>

<script setup lang="ts">
import type DataGridControl from 'o365.controls.DataGrid.ts';
import type { Ref, KeepAlive } from 'vue';
import { dataGridControlKey, bootstrapTransitionPromiseKey } from 'o365.modules.vue.injectionKeys.js';
import { default as DataGridMenuControl, IDataGridMenuTab } from 'o365.controls.DataGrid.GridMenu.ts';
/*
import ActiveFilters from 'o365.vue.components.ActiveFilters.vue';
import OColumnChooser from 'o365.vue.components.ColumnChooser.vue';
import GroupByFolder from 'o365.vue.components.GroupByFolder.vue';
import ExportData from 'o365.vue.components.ExportData.vue';
import GridLayouts from 'o365.vue.compoents.DataGrid.Layouts.vue';
import DetailsTab from 'o365.vue.components.DataGrid.GridMenu.DetailsTab.vue';
*/
import GridSidePanelTabs from 'o365.vue.components.DataGrid.SidePanelTabs.vue';
import ORowContainer from 'o365.vue.components.RowContainer.vue';
import OColContainer from 'o365.vue.components.ColContainer.vue';
import OSizerPanel from 'o365.vue.components.SizerPanel.vue';
import { inject, ref, onMounted, nextTick, useSlots, computed  } from 'vue';
import useErrorCapture from 'o365.vue.composables.ErrorCapture.ts';
import vTooltip from 'o365.vue.directive.tooltip.ts';
import translate from 'o365.modules.translate.ts';

import OTabs from 'o365.vue.components.Tabs.vue';
import OTab from 'o365.vue.components.Tab.vue';

import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';
const ActiveFilters = useAsyncComponent('o365.vue.components.ActiveFilters.vue');
const OColumnChooser = useAsyncComponent('o365.vue.components.ColumnChooser.vue');
const GroupByFolder = useAsyncComponent('o365.vue.components.GroupByFolder.vue');
const ExportData = useAsyncComponent('o365.vue.components.ExportData.vue');
const GridLayouts = useAsyncComponent('o365.vue.compoents.DataGrid.Layouts.vue');
const DetailsTab = useAsyncComponent('o365.vue.components.DataGrid.GridMenu.DetailsTab.vue');
const PropertiesList = useAsyncComponent('o365.vue.components.PropertiesList.vue');
const GroupBy = useAsyncComponent('o365.vue.components.DataGrid.GroupBy.vue');
const SummaryData = useAsyncComponent('o365.vue.components.DataGrid.GridMenu.SummaryData.vue');
const FilterStringEditor = useAsyncComponent('o365.vue.components.Filters.StringEditor.vue');

// import GridSidePanelItem from 'o365.vue.components.DataGrid.SidePanelItem.vue';

const props = withDefaults(defineProps<GridMenuProps>(), {
    initialVisible: true,
    tabContainerClass: 'row-container w-100 m-2',
    initialWidth: '400px',
});

const sizerPanelRef = ref(null);
const containerRef = ref(null);
const gridOptionsTabs = ref(null);
const slots = useSlots();

const dataGridControl: Ref<DataGridControl> = inject(dataGridControlKey, null);
const bootstrapTransitionPromise = inject(bootstrapTransitionPromiseKey, null);

function shouldShowMenuItem(pItem: string) {
    if (dataGridControl.value.props.hideMenuItems == null) {
        return true;
    } else {
        return !dataGridControl.value.props.hideMenuItems.includes(pItem);
    }
}

const columnsWatchTarget = computed(() => {
    return dataGridControl.value.dataColumns.columns.map(col => `${col.colId}-${col.order}-${col.hide}`);
});

const columnPanelTabsLength = computed(() => {
    let length = 0;
    if (dataGridControl.value.menuTabs.layoutsTabEnabled && shouldShowMenuItem('layouts')) {
        length += 1;
    }
    if (dataGridControl.value?.dataObject?.hasPropertiesData) {
        length += 1;
    }
    if (dataGridControl.value?.hasNodeData && dataGridControl.value?.nodeData.groupBy) {
        length += 1;
    }
    if (dataGridControl.value?.props.userSummaryAggregates) {
        length += 1;
    }
    return length;
})

const detailIframeUrlCopyTooltip = ref(translate('Copy url to clipboard'));
let detailIframeUrlCopyTooltipObj: any = null;

const detailIframeDisplayUrl = computed(() => {
    if (dataGridControl.value.props.detailIframe) {
        const url = new URL(dataGridControl.value.props.detailIframe, window.location.origin);
        url.searchParams.delete('HideNav');
        url.searchParams.delete('message');
        return url.pathname + url.search;
    } else {
        return undefined;
    }
});

function copyDetailUrl(e) {
    const element = e.target?.closest('.o365-grid-detail-link-container');
    if (element == null || !dataGridControl.value.props.detailIframe) { return; }
    const url = new URL(dataGridControl.value.props.detailIframe, window.location.origin);
    url.searchParams.delete('HideNav');
    url.searchParams.delete('message');
    const link = url.href;
    detailIframeUrlCopyTooltip.value = translate('Copied to clipboard');
    (detailIframeUrlCopyTooltipObj?._element as HTMLElement)?.addEventListener('hidden.bs.tooltip', () => {
        detailIframeUrlCopyTooltip.value = translate('Copy url to clipboard');
    }, { once: true });
    navigator.clipboard.writeText(link);
}

let cachedSizerSate = undefined;
let cachedActiveTab = undefined;
if (dataGridControl?.value) {
    if (dataGridControl.value.menuTabs) {
        cachedSizerSate = dataGridControl.value.menuTabs.sizerStateCache
        cachedActiveTab = dataGridControl.value.menuTabs.activeTab
    }
    dataGridControl.value.menuTabs = new DataGridMenuControl({
        dataGridControl: dataGridControl.value,
        enableGroupByFolders: props.groupByFolders,
        initialVisible: props.initialVisible,
        detailTabTitle: props.detailTabTitle,
        enableDetails: !!props.iframeSrc || !!props.tabs || slots.detailTab,
        messageChannelId: props.messageChannelId,
        messageChannelFunctions: props.messageChannelFunctions,
        leftSidepanel: props.leftSidepanel,
        getTabsControl: () => gridOptionsTabs.value
    });
}

onMounted(() => {
    dataGridControl.value.menuTabs.sizerControl = sizerPanelRef.value?.getSizer();
    dataGridControl.value.menuTabs.sizerPanelRef = sizerPanelRef;
    dataGridControl.value.menuTabs.mountedInitialize({
        bootstrapTransitionPromise:  bootstrapTransitionPromise,
        sizerState: cachedSizerSate,
        previousActiveTab: cachedActiveTab
    });
});


const [capturedError, ErrorRenderer] = useErrorCapture({
    consoleMessagee: `Error encountered when trying to render grid sidepanel`,
    errorRenderFunctionOptions: {
        type: 'card',
        uiMessage: 'An unhandled error has occurred when rendering the contents of this sidepanel'
    }
});
</script>

<style scoped>
    .left-sidepanel {
        padding-right: 0.25rem!important;
    }
    .sidepanel-items-container {
        display: flex;
        flex-direction: column;
    }
</style>